<template>
    <div>
         <van-nav-bar
      title="我的包裹"
      left-arrow
      @click-left="onClickLeft"
      fixed
      :z-index="101"
      class="dark_bg"
    />
        <div class='logistics-list'>
        <waybillList class='list'/>
        </div>
    </div>
</template>

<script>
import waybillList from "../public/waybillList";

export default {
    components: {
   
    waybillList,
  },
  methods:{
    onClickLeft() {
      this.$router.back();
    },
  }
    
}
</script>
<style lang='less' scoped>
  .logistics-list{
    margin-top: .88rem;
    padding: .3rem;
  }
</style>